import { mapState, mapActions, mapGetters } from 'vuex';

import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import KnAutoComplete from '../.././../shared/components/KnAutoComplete.vue';
import KnTextField from '../.././../shared/components/KnTextField.vue';
import { getItem } from '../../../../api/api-methods';
import {
  CfdiTypes,
  Currencies,
  FiscalRegimen,
  PaymentForms,
  PaymentMethods,
} from '../../../income/helpers/incomeOptions';

export default {
  components: { KnTextField, KnAutoComplete },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useGeneric: {
      type: Boolean,
    },
    originalTaxInfo: {
      type: Object,
    },
  },
  data() {
    return {
      invoiceUse: [],
      regimentTypes: [],
      currency: [],
      paymentForms: [],
      cfdiTypes: [],
      paymentMethods: [],
      isEditMode: false,
      localIsChecked: this.useGeneric,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'currentInstitution']),
    ...mapGetters(['billingEnabled']),
    invoiceUseLabel() {
      // console.log(this.model);
      // console.log('invoiceUse', this.invoiceUse);

      const option = this.model.uso_factura
        ? this.invoiceUse.find(
            (option) =>
              option.id ===
              (typeof this.model.uso_factura === 'object'
                ? this.model.uso_factura.id
                : this.model.uso_factura)
          )
        : null;
      return option ? option.dato : '';
    },
    fiscalRegimeLabel() {
      const option = this.model.regimen_fiscal
        ? this.regimentTypes.find(
            (option) =>
              option.Value ===
              (typeof this.model.regimen_fiscal === 'object'
                ? this.model.regimen_fiscal.Value
                : this.model.regimen_fiscal)
          )
        : null;
      return option ? option.Name : '';
    },
    paymentFormLabel() {
      const option = this.paymentForms.find(
        (option) => option.Value === this.model.forma_pago
      );
      return option ? option.Name : '';
    },
    currencyLabel() {
      const option = this.currency.find(
        (option) => option.Value === this.model.moneda
      );
      return option ? option.Name : '';
    },
    cfdiTypeLabel() {
      const option = this.cfdiTypes.find(
        (option) => option.Value === this.model.tipo_cfdi
      );
      return option ? option.Name : '';
    },
    paymentMethodLabel() {
      const option = this.paymentMethods.find(
        (option) => option.Value === this.model.metodo_pago
      );
      return option ? option.Name : '';
    },
  },
  watch: {
    useGeneric(newValue) {
      if (newValue) {
        this.applyGenericInfo();
        this.isEditMode = false;
      } else {
        if (!this.originalTaxInfo || !this.originalTaxInfo.id) {
          this.isEditMode = true;
        }
        this.restoreOriginalInfo();
      }
    },
  },
  methods: {
    ...mapActions('facturama', ['fetchDataFacturama', 'fetchDefaultTaxInfo']),
    async loadFormOptions() {
      try {
        const usoInvoice = await getItem('/app-personas/uso-factura?limit=30');
        this.invoiceUse = usoInvoice.results;

        if (this.billingEnabled) {
          const { password_facturama, usuario_facturama } = await this
            .currentInstitution;
          const formOptions = await this.fetchDataFacturama({
            user: usuario_facturama,
            password: password_facturama,
          });

          this.cfdiTypes = formOptions.cfdiTypes;
          this.regimentTypes = formOptions.regimentTypes;
          this.currency = formOptions.currency;
          this.paymentForms = formOptions.paymentForms;
          this.paymentMethods = formOptions.paymentMethods;
        } else {
          this.cfdiTypes = CfdiTypes;
          this.regimentTypes = FiscalRegimen;
          this.currency = Currencies;
          this.paymentForms = PaymentForms;
          this.paymentMethods = PaymentMethods;
        }
      } catch (error) {
        console.error('Error al cargar datos de Facturama', error);
      } finally {
        this.loading = false;
      }
    },

    emitChangeInfo() {
      this.$emit('changeGenericInfo', this.regimentTypes, this.invoiceUse);
    },
    emitHandleCheck(value) {
      this.$emit('changeGenericCheck', value);
    },
    emitTaxInfo() {
      this.$emit('update-tax-info', {
        taxInfo: this.model,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },
    async applyGenericInfo() {
      const genericTaxInfo = await this.fetchDefaultTaxInfo({
        user: this.currentInstitution.usuario_facturama,
        password: this.currentInstitution.password_facturama,
        institutionId: this.institutionId,
      });

      this.$emit('update-tax-info', {
        taxInfo: genericTaxInfo,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },
    restoreOriginalInfo() {
      if (this.originalTaxInfo) {
        this.$emit('update-tax-info', {
          taxInfo: this.originalTaxInfo,
          regimentTypes: this.regimentTypes,
          invoiceUse: this.invoiceUse,
        });
      }
    },
  },

  async created() {
    await this.loadFormOptions();
  },
};
